export const RENTAL_ITEM_LIST = `query (
    $facilityId: Int!
    $filter: ListRentalItemReservationFilterInput
) {
  rentalItemList(facilityId: $facilityId) {
      id
      quantity # 数量（この数だけ行を出す）
      rentalItemDef {
        name
        price
        rentalPeriod
      }
  }
  
  rentalItemReservationList(
    filter: $filter
     
    orderBy: [{
      fieldName: "reserve.fromDate"
      direction: "ASC"
    }]
  ) {
    items {
      id
      quantity
      fromDate #DateString!
      toDate # DateString!
      block #trueならブロック予約なので、以下のBookingはNullになる
      note #blockの場合はこちらを表示
      rentalItemId
      bookingId
      booking {
          id
          bookingGroupId
          bookingTypeId #色の判断に使用できる
          representative {
          name #この名前を表示する
        }
      }
    }
  }
}`
