<template>
  <v-dialog v-model="isShowConfirmCreateBlock" width="320px" @click:outside="closeDialog">
    <v-form ref="formBlock">
      <v-card>
        <v-card-title class="title-dialog justify-space-between">
          <span class="title-block">貸出品ブロック</span>
           <v-select
            v-model="lendingSelected"
            :items="listLending"
            dense
            small
            color="var(--text__gray)"
            outlined
            hide-details
            item-text="nameConvert"
            item-value="id"
            class="v-select-custom ml-2"
            disabled
          ></v-select>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="numberRangerSelected"
            :items="numberRanger"
            dense
            color="var(--text__gray)"
            outlined
            hide-details
            class="v-select-custom max-wd"
          ></v-select>
          <p class="font-weight-black mb-0 fz-12 color-text-61">適用範囲</p>
          <v-menu
            v-model="menuDateFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div class="mb-1">
                <v-icon>mdi-calendar-month</v-icon>
                <v-btn
                  outlined
                  v-on="on"
                  small
                  class="bg--btn-white mr-5 ml-1 btn-custom-gray fz-13"
                  >{{ formatDate(fromDateBlock) }}
                  </v-btn>
                  <span class="ml-n3 fz-13">から</span>
              </div>
            </template>
            <v-date-picker
              v-model="fromDateBlock"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDateFrom = false"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
          <!-- Date picker -->
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div>
                <v-icon>mdi-calendar-month</v-icon>
                <v-btn
                  outlined
                  v-on="on"
                  small
                  class="bg--btn-white mr-5 ml-1 btn-custom-gray fz-13"
                  >{{ formatDate(toDateBlock) }}
                  </v-btn>
                  <span class="ml-n3 fz-13">まで</span>
              </div>
            </template>
            <v-date-picker
              v-model="toDateBlock"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDate = false"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
          <span class="red--text fz-10" v-if="errorDate">終了日はスタート日より大きくする必要があります</span>
          <p class="font-weight-black mb-0 mt-5 fz-12 color-text-61">コメント</p>
          <v-text-field
            v-model="noteBlock"
            outlined
            dense
            type="text"
            class="form-control"
            disabled
          ></v-text-field>
        <!--  -->
        </v-card-text>
        <v-card-text class="mt-n5">
          <v-row>
            <v-col :cols="6" class="d-flex justify-start">
              <v-btn
                width="80"
                color="var(--bt__blue)"
                class="mr-3 d-flex align-center btn_status white--text"
                @click="updateRentalItemReservation()"
              >
                適用
              </v-btn>
              <v-btn
                width="80"
                color="var(--bt__red)"
                class="mr-3 d-flex align-center btn_status white--text"
                @click="removeFormBlock"
              >
                解除
              </v-btn>
              <v-btn
                width="104"
                class="black--text btn_status btn--gray-ounlined"
                outlined
                color="var(--bt__white)"
                @click="closeDialog"
              >
                <span class="text--gray-darken">キャンセル</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import { formatDate, formatCurrency, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import { REMOVE_BLOCK_LENDING, UPDATE_BLOCK_LENDING } from '@/api/graphql/calendar/removeBlockLending'
import gql from 'graphql-tag'

export default {
  name: 'CalendarLendingBlock',
  props: {
    isShow: {
      type: Boolean,
      required: false,
      default: true
    },
    listLending: {
      type: Array,
      required: true,
      default: () => []
    },
    lendingBlockRemove: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    isShowConfirmCreateBlock () {
      return this.isShow
    }
  },
  data () {
    return {
      noteBlock: '',
      errorDate: false,
      numberRangerSelected: 1,
      numberRanger: this.rangeNumber(1, 101, 1),
      lendingSelected: null,
      menuDate: false,
      menuDateFrom: false,
      toDateBlock: new Date().toISOString().substr(0, 10),
      fromDateBlock: new Date().toISOString().substr(0, 10)
    }
  },
  mounted () {
    this.listLending.map((item) => {
      item.nameConvert = `${item.rentalItemDef.name} ¥${formatCurrency(item.rentalItemDef.price)}/${item.rentalItemDef.rentalPeriod === 'Stay' ? '滞在' : '泊'}`
      return item
    })
    this.lendingSelected = this.lendingBlockRemove.rentalItemId
    this.numberRangerSelected = this.lendingBlockRemove.quantity
    this.toDateBlock = this.lendingBlockRemove.toDate
    this.fromDateBlock = this.lendingBlockRemove.fromDate
    this.noteBlock = this.lendingBlockRemove.note
    this.errorDate = false
  },

  methods: {
    formatDate,
    formatCurrency,
    handlErrorView,
    getCopyErrorTextView,
    rangeNumber (start, end, step) {
      return Array.from(Array.from(Array(Math.ceil((end - start) / step)).keys()), x => start + x * step)
    },
    ...mapMutations(['setCopyErrorText', 'setAlertSuccess', 'setErrorDialog', 'setAlertError', 'setLoadingOverlayShow', 'setLoadingOverlayHide', 'setPermissionUser', 'setRoleAdminUser']),

    closeDialog () {
      this.$refs.formBlock.resetValidation()
      this.noteBlock = ''
      this.lendingSelected = null
      this.numberRangerSelected = 1
      this.$emit('update:isShow', false)
      this.$emit('close')
    },

    // remove block
    async removeFormBlock () {
      this.setLoadingOverlayShow()
      const variables = {
        data: {
          rentalItemReservationId: parseInt(this.lendingBlockRemove.id),
          newQuantity: 0 // 0にするとキャンセル
        }
      }
      await this.$apollo.mutate({
        mutation: gql`${REMOVE_BLOCK_LENDING}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        if (data.data.updateRentalItemReservation && data.data.updateRentalItemReservation.id) {
          this.setAlertSuccess(this.$t('messages.deletedSuccessfully'))
          this.closeDialog()
          this.$emit('fetchList')
        }
        this.setLoadingOverlayHide()
      }).catch(async (error) => {
        this.setLoadingOverlayHide()
        this.setCopyErrorText(this.getCopyErrorTextView(REMOVE_BLOCK_LENDING, variables, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },

    // update block
    async updateRentalItemReservation () {
      this.errorDate = false
      if (moment(this.toDateBlock).isBefore(this.fromDateBlock)) {
        this.errorDate = true
      }
      if (this.$refs.formBlock.validate() && !this.errorDate) {
        this.setLoadingOverlayShow()
        const variables = {
          data: {
            rentalItemReservationId: parseInt(this.lendingBlockRemove.id),
            newFromDate: this.fromDateBlock,
            newToDate: this.toDateBlock,
            newQuantity: parseInt(this.numberRangerSelected)
          }
        }
        await this.$apollo.mutate({
          mutation: gql`${UPDATE_BLOCK_LENDING}`,
          variables: variables,
          fetchPolicy: 'no-cache'
        }).then((data) => {
          if (data.data.updateRentalItemReservation) {
            this.setAlertSuccess(this.$t('messages.successfulUpdate'))
            this.closeDialog()
            this.$emit('fetchList')
          }
          this.setLoadingOverlayHide()
        }).catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(UPDATE_BLOCK_LENDING, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.title-block {
  font-size: 14px;
  color: #AAAAAA;
}
::v-deep .v-select__selection{
  font-size: 14px !important;
  color: #AAAAAA !important;
}
::v-deep .v-messages__message {
  font-size: 12px !important;
}
.color-text-61 {
  color: #616161;
}
.v-select-custom {
  max-width: 150px;
}
.btn_status {
  font-size: 16px;
}
.fz-10 {
  font-size: 10px;
}
.fz-9 {
  font-size: 9px;
}
.fz-11 {
  font-size: 11px;
}
.fz-12 {
  font-size: 12px;
}
.fz-13 {
  font-size: 13px;
}
.btn--gray-ounlined {
  border: solid 1px #BDBDBD !important;
}
.max-wd {
  max-width: 100px;
}
::v-deep .v-messages__message {
  font-size: 10px;
}
</style>
