<template>
  <base-calendar>
    <template v-slot:actions>
      <SearchBooking></SearchBooking>
    </template>
    <template v-slot:default>
      <div class="mt-5 flex-header">
        <v-btn icon @click="filterFromDate = nextOrPrevDay(filterFromDate, -1)"><v-icon size="35">mdi-chevron-double-left</v-icon></v-btn>
        <v-btn icon @click="filterFromDate = nextOrPrevDay(filterFromDate, 1)"><v-icon size="35">mdi-chevron-double-right</v-icon></v-btn>
        <v-btn outlined small class="mr-5 ml-1 btn-custom-gray fz-14 color-black-text" @click="chooseToday"> {{ $t('common.toDay') }} </v-btn>

        <!-- Date picker -->
          <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div>
                <v-icon>mdi-calendar-month</v-icon>
                <v-btn
                  outlined
                  v-on="on"
                  small
                  class="bg--btn-white mr-5 ml-1 btn-custom-gray fz-14 color-black-text"
                  >{{ formatDate(filterFromDate, true) }}</v-btn
                >
              </div>
            </template>
            <v-date-picker
              v-model="filterFromDate"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDate = false"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
        <!--  -->

        <span class="fz-12 color-black-text">{{ $t('roomAssignment.period') }}</span>
        <v-select
          v-model="forTimeFilterSelected"
          :items="forTimeFilter"
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          item-text="name"
          item-value="id"
          class="v-select-custom ml-2"
        ></v-select>
        <span class="ml-3 fz-12 color-black-text">{{ $t('common.facility') }}</span>
        <v-select
          v-model="facilitySelected"
          :items="facilitySelectList.length > 0 ? facilitySelectList : []"
          dense
          small
          item-text="name"
          item-value="id"
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom ml-2 facility-list"
        ></v-select>
      </div>
      <div class="my-5 mx-0 main_calendar pb-0 main_calendar-overflow">
        <div class="calendar-custom d-block large" ref="heightLending">
          <div class="calendar-custom_inner">
            <div class="calendar-custom_block">
              <div class="calendar-custom_item fz-12 color-black-text" v-if="facilitySelectList.length > 0" :title="showTextFacility(facilitySelected, true)">{{ showTextFacility(facilitySelected, false) }}</div>
            </div>
            <div class="d-inline-flex">
              <template v-for="(occurrencesDateFilter, index) in occurrencesDateFilter">
                <div :style="`width: ${(100 * occurrencesDateFilter)}px !important; border: 1px solid #dee2e6; border-bottom:none; border-left: none;`" :key="`${occurrencesDateFilter}-${index}`" class="pt-1 pl-2 fz-13 color-black-text">{{ index }}
                    <template v-if="checkShowTextDateFilter(occurrencesDateFilter)">
                      <span class="sub-occurrencesDateFilter " v-for="num in checkShowTextDateFilter(occurrencesDateFilter)" :key="`countText-${num}`" style="margin-left: 420px">
                        {{ index }}
                      </span>
                    </template>
                  </div>
              </template>
            </div>
          </div>
          <div class="calendar-custom_inner">
            <div class="calendar-custom_block">
              <div class="calendar-custom_item fz-12 color-black-text">貸出品 貸出単価（税抜）</div>
            </div>
            <template v-for="(infoExhibit, index) in getInfoExhibitsLending">
              <div :key="index" class="calendar-custom_block ranger color-black-text">
                <div class="calendar-custom_item rangeritem" v-text="formatDate(infoExhibit.date)" :class="{'color-red-text' : formatDate(infoExhibit.date).substr(3, 4) == '(日)' || arrHolidays.includes(infoExhibit.date)}"></div>
              </div>
            </template>
          </div>

          <div class="calendar-row calendar-custom_inner custom-heigth" v-for="(rentalList, index) in getRentalListsLending" :key="index" :class="{overbook: rentalList.overbook}">
            <div class="calendar-custom_block">
              <div class="calendar-custom_item d-flex justify-space-between fz-12 color-black-text">
                {{ rentalList.rentalItemDef.name }}
                <!-- - {{ rentalList.id }} - {{ rentalList.quantity }} -->
                <span v-if="rentalList.id">{{ `¥ ${formatCurrency(rentalList.rentalItemDef.price)} / ${rentalList.rentalItemDef.rentalPeriod === 'Stay' ? '滞在' : '泊'}` }}</span>
              </div>
            </div>
            <template v-for="(infoExhibit, index) in getInfoExhibitsLending">
              <div :key="index" class="calendar-custom_block ranger">
                <template v-for="(list, index) in infoExhibit.list">
                  <div
                    v-if="list.rentalItemListId == rentalList.id"
                    :key="index"
                    class="calendar-custom_item rangeritem"
                    :class="list.count_stock < 0 ? 'color-red-text' : ''"
                  >
                    {{ showTextCountStock(list.count_stock) }}
                  </div>
                </template>
                <div
                  v-on:drop="drop"
                  v-on:dragover="allowDrop"
                  class="calendar-custom_item rangeritem no-padding"
                  :id="'d-' + formatDateRanger(infoExhibit.date).substr(0, 10)"
                  @click.stop="checkUser ? clickBlockCreate(infoExhibit, rentalList) : () => {}"
                >

                  <div class="reservation-table-container" v-if="index === 0 && rentalItemReservationListWithOverlap[rentalList.id]">
                    <template v-for="(customerList, idx) in rentalItemReservationListWithOverlap[rentalList.id].blocks">
                      <div
                        :id="'drag' + idx"
                        :key="customerList.id"
                        :style="customerList.style"
                        class="calendar_event custom_ranger_block reservation-item block bg-contract--parking-block"
                        @click.stop="checkUser ? clickBlockEdit(customerList) : () => {}"
                      >
                        <span class="calendar_event_item text-center pd-text-block fz-11">
                          {{  showTextRanger(customerList.block, customerList) }}
                        </span>
                      </div>
                    </template>
                    <template v-for="(customerList, idx) in rentalItemReservationListWithOverlap[rentalList.id].normals">
                      <div
                        :id="'drag' + idx"
                        :key="customerList.id"
                        :style="customerList.style"
                        :class="(customerList.booking && bookingTypesList.lenght > 0) ? itemRowBackground(customerList.booking.bookingTypeId, true) : itemRowBackground( 'その他予約', false)"
                        class="calendar_event custom_ranger reservation-item normal"
                        @click.stop="clickRanger(customerList)"
                      >
                        <span class="calendar_event_item text-center fz-11">
                          <!-- {{customerList.facilityParkingId}}-{{customerList.id}}- -->
                          {{ showTextRanger(customerList.block, customerList) }}
                        </span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <CalendarLendingBlock
        v-if="isShowConfirmCreateBlock"
        :list-lending="typeCarsRoot"
        :lending-block-date="lendingDate"
        :lending-block-current="lendingBlockCurrent"
        @close="isShowConfirmCreateBlock = false"
        @fetchList="rentalItemList(true)"
      />
      <CalendarLendingBlockRemove
        v-if="isShowConfirmRemoveBlock"
        :list-lending="typeCarsRoot"
        :lending-block-remove="lendingBlockRemove"
        @close="isShowConfirmRemoveBlock = false"
        @fetchList="rentalItemList(true)"
      />
    </template>
  </base-calendar>
</template>

<script>
import gql from 'graphql-tag'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import moment from 'moment'
import _ from 'lodash'
import BaseCalendar from '../../../../components/Calendar/BaseCalendar'
import { listContractBackground } from '@/constants/contractColors'
import { nextOrPrevDay, getDaysArray } from '@/utils/handleDate'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { BOOKING_TYPES_LIST } from '@/api/graphql/bookingDetail/booking-detail-basic'
import { RENTAL_ITEM_LIST } from '@/api/graphql/calendar/rentalItemList'
import { formatCurrency, handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import CalendarLendingBlock from '@/components/Calendar/CalendarLendingBlock'
import CalendarLendingBlockRemove from '@/components/Calendar/CalendarLendingBlockRemove'
import SearchBooking from '@/components/SearchBooking'
import { isoDate } from '@/utils/dateUtil'
import { calculateOverlpas } from '@/utils/calendar'

export default {
  name: 'CalendarExhibitsForHire',
  components: {
    BaseCalendar,
    CalendarLendingBlock,
    SearchBooking,
    CalendarLendingBlockRemove
  },
  created () {
    this.getBookingTypeList()
    this.getFacility()
    this.getHolidays({
      fromDate: this.filterFromDate,
      toDate: this.filterToDate
    }).then(result => {
      result.data.holidays.forEach(e => {
        this.arrHolidays.push(e.holidayDate)
      })
    })
  },
  data () {
    return {
      arrHolidays: [],
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      infoExhibits: [],
      rentalLists: [],
      customerLists: [],

      // data filter
      menuDate: false,
      forTimeFilterSelected: 4,
      forTimeFilter: [
        {
          id: 1,
          name: '1年'
        },
        {
          id: 2,
          name: '3ヶ月'
        },
        {
          id: 3,
          name: '1ヶ月'
        },
        {
          id: 4,
          name: '2週間'
        },
        {
          id: 5,
          name: '1週間'
        }
      ],
      nextOrPrevDay,
      getDaysArray,
      facilitySelectList: [],
      facilitySelected: null,
      filterFromDate: new Date().toISOString().substr(0, 10),

      // list lending
      getDaysArrayRoot: null,
      loadingPage: false,
      typeCars: null,
      typeCarsRoot: [],
      heightLending: 0,
      occurrencesDateFilter: null,
      arrDay: [],
      bookingTypesList: [],

      // create block lending
      isShowConfirmCreateBlock: false,
      lendingDate: null,
      lendingBlockCurrent: null,

      // remove block lending
      lendingBlockRemove: null,
      isShowConfirmRemoveBlock: false

    }
  },
  computed: {
    ...mapGetters(['getRentalListsLending', 'getTypeCarsRootLending', 'getInfoExhibitsLending', 'getCustomerListsLending']),
    filterToDate () {
      const baseDate = new Date(this.filterFromDate)
      switch (this.forTimeFilterSelected) {
        case 1:
          return isoDate(baseDate.setFullYear(baseDate.getFullYear() + 1))
        case 2:
          return isoDate(baseDate.setMonth(baseDate.getMonth() + 3))
        case 3:
          return isoDate(baseDate.setMonth(baseDate.getMonth() + 1))
        case 4:
          return isoDate(baseDate.setDate(baseDate.getDate() + 14))
        case 5:
          return isoDate(baseDate.setDate(baseDate.getDate() + 7))
        default:
          return isoDate(baseDate.setMonth(baseDate.getMonth() + 1))
      }
    },
    rentalItemReservationListWithOverlap () {
      if (!this.getCustomerListsLending) return []

      const result = calculateOverlpas(
        this.getCustomerListsLending,
        rsv => rsv.rentalItemId,
        this.filterFromDate,
        this.filterToDate
      )

      return result
    }
  },
  watch: {
    facilitySelected (newVal) {
      if (newVal && this.loadingPage) {
        this.rentalItemList()
      }
    },
    filterFromDate (newVal) {
      if (newVal && this.loadingPage) {
        this.rentalItemList()
      }
    },
    forTimeFilterSelected (newVal) {
      if (this.loadingPage) {
        this.rentalItemList()
      }
    }
  },
  methods: {
    formatCurrency,
    handlErrorView,
    getCopyErrorTextView,
    ...mapMutations({
      setCopyErrorText: 'setCopyErrorText',
      setRentalListsLending: 'setRentalListsLending',
      setTypeCarsRootLending: 'setTypeCarsRootLending',
      setInfoExhibitsLending: 'setInfoExhibitsLending',
      setCustomerListsLending: 'setCustomerListsLending',
      setLoadingOverlayShow: 'setLoadingOverlayShow',
      setLoadingOverlayHide: 'setLoadingOverlayHide',
      setPermissionUser: 'setPermissionUser',
      setRoleAdminUser: 'setRoleAdminUser'
    }),
    ...mapActions([
      'getHolidays'
    ]),
    allowDrop (event) {
      event.preventDefault()
    },
    drag (event) {
      event.dataTransfer.setData('text', event.target.id)
    },
    drop (event) {
      event.preventDefault()
      var data = event.dataTransfer.getData('text')
      event.target.appendChild(document.getElementById(data))
    },
    numbDays (c, d, checkRanger = false) {
      const start = moment(c, 'YYYY-MM-DD')
      const end = moment(d, 'YYYY-MM-DD')
      // Difference in number of days
      const diffDay = moment.duration(end.diff(start)).asDays()
      if (diffDay > 0) {
        if (checkRanger) {
          return diffDay + 1
        }
        return diffDay
      }
      return 1
    },
    numbDaysRentalItemReservationList (c, d) {
      const start = moment(c, 'YYYY-MM-DD')
      const end = moment(d, 'YYYY-MM-DD')
      // Difference in number of days
      return moment.duration(end.diff(start)).asDays()
    },
    itemRowBackground (item, statusBookingType = false) {
      if (statusBookingType) {
        const nameColorTmp = this.bookingTypesList.filter(itemBook => itemBook.id === item)
        const codeColor = nameColorTmp.length > 0 ? nameColorTmp[0].code : ''
        const nameColor = nameColorTmp.length > 0 ? nameColorTmp[0].name : ''
        let colorBg = 'その他予約'
        if (nameColor || codeColor) {
          const keyListContractBackground = Object.keys(listContractBackground)
          keyListContractBackground.map((itemBg) => {
            if (itemBg.includes(nameColor) || itemBg.includes(codeColor)) {
              colorBg = itemBg
            }
          })
          return listContractBackground[colorBg]
        }
        return listContractBackground[colorBg]
      }
      return listContractBackground[item]
    },
    formatDateRanger (value) {
      return moment(value).format('yyyy-MM-DD')
    },

    checkShowTextDateFilter (occurrencesDateFilter) {
      let countText = 0
      if (occurrencesDateFilter > 5) {
        countText = Math.floor(occurrencesDateFilter / 6)
      }
      if (occurrencesDateFilter === 30 || occurrencesDateFilter === 31) {
        countText = countText + 1
      }
      return countText
    },
    formatDate (value, fomat = false) {
      var d = moment(value).format('dddd')
      switch (d) {
        case 'Sunday':
          d = '日'
          break
        case 'Monday':
          d = '月'
          break
        case 'Tuesday':
          d = '火'
          break
        case 'Wednesday':
          d = '水'
          break
        case 'Thursday':
          d = '木'
          break
        case 'Friday':
          d = '金'
          break
        case 'Saturday':
          d = '土'
          break
      }
      if (fomat) {
        return moment(value).format('yyyy年MM月DD日 (' + d + ')')
      }
      return moment(value).format('D (' + d + ')')
    },

    async getBookingTypeList () {
      await this.$apollo
        .query({
          query: gql`${BOOKING_TYPES_LIST}`
        })
        .then((data) => {
          this.bookingTypesList = data.data.bookingTypesList
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(BOOKING_TYPES_LIST, null, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
    },

    // begin filter
    async getFacility () {
      const query = `
                query {
          facilityList(filter: {
            types: [TVS]
          }) {
            id
            name
          }
        }
      `
      await this.$apollo.query({
        query: gql`${query}`
      }).then((data) => {
        const facilityList = data.data.facilityList
        if (facilityList.length > 0) {
          this.facilitySelectList = this.facilitySelectList.concat(facilityList)
          this.facilitySelected = this.facilitySelectList[0].id
          this.rentalItemList()
        }
      }).catch(async (error) => {
        this.setCopyErrorText(this.getCopyErrorTextView(query, null, error.graphQLErrors))
        const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
        if (errorTmp) {
          this.setAlertError(errorTmp, { root: true })
        }
      })
    },
    async chooseToday () {
      this.heightLending = 0
      this.filterFromDate = this.$today()
    },
    // end filter

    // begin list lending room
    formatRangerFilter (value) {
      if (value) {
        return moment(value, 'yyyy年MM月').format('yyyy年MM月')
      }
      return ''
    },
    showTextRanger (block = false, customerList = null) {
      let text = ''
      if (customerList) {
        if (block) {
          if (customerList.note.length > 30) {
            text += `${customerList.note.substr(0, 15)}...${customerList.note.substr(-10)}`
          } else {
            text += customerList.note
          }
        }
        if (customerList.booking && customerList.booking.representative) {
          const repName = customerList.booking.representative?.name
          if (!repName) text += ''
          else {
            if (customerList.booking.representative?.name?.length > 30) {
              text += `${customerList.booking.representative.name.substr(0, 15)}...${customerList.booking.representative.name.substr(-10)}`
            } else {
              text += customerList.booking.representative.name
            }
          }
        }
      }
      return `(${customerList.quantity}) ${text}`
    },
    showTextFacility (falcilityId, allText = false) {
      const tmpTextFacility = this.facilitySelectList.filter(item => item.id === falcilityId)
      if (tmpTextFacility.length > 0) {
        if (allText) {
          return tmpTextFacility[0].name
        }
        if (tmpTextFacility[0].name.length >= 100) {
          return `${tmpTextFacility[0].name.substr(0, 15)}...${tmpTextFacility[0].name.substr(-10)}`
        }
        return tmpTextFacility[0].name
      }
      return ''
    },
    showTextCountStock (countStock = 0) {
      if (countStock < 0) {
        return `0(${countStock})`
      }
      return countStock
    },
    showTopBlockOverwrite (overwrite = false) {
      if (overwrite) {
        return 21
      }
      return 0
    },
    async rentalItemList (reload = false) {
      this.setLoadingOverlayShow()
      this.infoExhibits = []
      // begin process template date header
      const getDaysArray = this.getDaysArray(new Date(this.filterFromDate), new Date(this.filterToDate))
      this.getDaysArrayRoot = getDaysArray
      const getDaysArrayFomat = getDaysArray.map((ite) => {
        ite = this.formatRangerFilter(ite)
        return ite
      })
      getDaysArray.map((itemDay) => {
        const item = {
          date: itemDay,
          list: []
        }
        this.infoExhibits.push(item)
      })
      this.occurrencesDateFilter = _.countBy(getDaysArrayFomat)
      // end process template date header

      if (!reload) {
        this.heightLending = 0
        this.rentalLists = []
        this.customerLists = []
        this.typeCars = []
        this.typeCarsRoot = []
        this.setCustomerListsLending({ customerLists: [] })
        this.setInfoExhibitsLending({ infoExhibits: [] })
        this.setTypeCarsRootLending({ typeCarsRoot: [] })
        this.setRentalListsLending({ rentalLists: [] })
      }

      const facilityId = parseInt(this.facilitySelected)
      const variables = {
        facilityId,
        filter: {
          fromDate: this.filterFromDate,
          toDate: this.filterToDate,
          facilityId,
        }
      }
      await this.$apollo.query({
        query: gql`${RENTAL_ITEM_LIST}`,
        variables: variables,
        fetchPolicy: 'no-cache'
      }).then((data) => {
        this.loadingPage = true
        const rentalItemList = data.data.rentalItemList
        const rentalItemReservationList = data.data.rentalItemReservationList.items

        // endcheck date overwrite in rentalItemReservationList
        this.customerLists = rentalItemReservationList

        // begin update fromDate if date range change (fromDate not in array getDaysArray)
        if (rentalItemReservationList.length > 0) {
          getDaysArray.forEach((date) => {
            this.customerLists.forEach((item) => {
              const numberDay = this.numbDaysRentalItemReservationList(item.fromDate, item.toDate)
              const validateDateBetweenTwoDates = this.validateDateBetweenTwoDates(item.fromDate, item.toDate, date)
              if (numberDay >= 1 && validateDateBetweenTwoDates && !getDaysArray.includes(item.fromDate)) {
                item.fromDateRoot = item.fromDate
                item.fromDate = date
                item.position = true
                item.numberDay = this.numbDaysRentalItemReservationList(date, item.toDate)
              }
            })
          })
        }
        // end update fromDate if date range change (fromDate not in array getDaysArray)
        this.setCustomerListsLending({ customerLists: this.customerLists })

        if (rentalItemList.length > 0) {
          // list rentalLists
          this.typeCarsRoot = rentalItemList
          const arrRentalItemListClone = []
          this.rentalLists = rentalItemList.map((item) => {
            item.idSort = item.id
            const addDummy = (count, overbook) => {
              for (let i = 0; i < count; i++) {
                arrRentalItemListClone.push({
                  id: 0,
                  idSort: item.id,
                  quantity: item.quantity,
                  rentalItemDef: {
                    name: '',
                    price: '',
                    rentalPeriod: '',
                  },
                  overbook,
                })
              }
            }
            addDummy(Math.max(item.quantity, 1), false);
            // check the max overlaps. If there is an overlap, add the difference to the quantity
            const reservationData = this.rentalItemReservationListWithOverlap[item.id]
            if (
              reservationData?.maxOverlap &&
              reservationData.maxOverlap >= item.quantity
            ) {
              addDummy(reservationData.maxOverlap - item.quantity + 1, true)
            }
            return item
          })
          this.rentalLists = _.orderBy([...this.rentalLists, ...arrRentalItemListClone], ['idSort'], ['asc'])
          // console.log(this.rentalLists, 'getRentalListsLending')
          this.setTypeCarsRootLending({ typeCarsRoot: rentalItemList })
          this.setRentalListsLending({ rentalLists: this.rentalLists })
        }

        // process data rentalItemReservationList
        const arrIdRes = rentalItemReservationList.map(itemRes => itemRes.rentalItemId)
        this.infoExhibits.forEach((itemExh) => {
          rentalItemList.forEach((itemList) => {
            if (arrIdRes.includes(itemList.id)) {
              let countDay = 0
              rentalItemReservationList.forEach((itemRes) => {
                if (this.validateDateBetweenTwoDates(itemRes.fromDate, itemRes.toDate, itemExh.date) && itemList.id === itemRes.rentalItemId) {
                  countDay += itemRes.quantity
                }
              })
              const dataListRes = {
                rentalItemListId: itemList.id,
                count_stock: itemList.quantity - countDay
              }
              itemExh.list.push(dataListRes)
            } else {
              const dataList = {
                rentalItemListId: itemList.id,
                count_stock: itemList.quantity
              }
              itemExh.list.push(dataList)
            }
          })
        })
        this.setInfoExhibitsLending({ infoExhibits: this.infoExhibits })
        // console.log(this.infoExhibits, 'getInfoExhibitsLending')

        // height list lending
        this.$nextTick(() => {
          this.heightLending = this.$refs.heightLending ? this.$refs.heightLending.clientHeight : 0
        })
        this.setLoadingOverlayHide()
      })
        .catch(async (error) => {
          this.loadingPage = true
          this.setLoadingOverlayHide()
          this.setCopyErrorText(this.getCopyErrorTextView(RENTAL_ITEM_LIST, variables, error.graphQLErrors))
          const errorTmp = await this.handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
    },
    getvalidDate (d) {
      return new Date(d)
    },
    validateDateBetweenTwoDates (fromDate, toDate, givenDate) {
      return this.getvalidDate(givenDate) < this.getvalidDate(toDate) && this.getvalidDate(givenDate) >= this.getvalidDate(fromDate)
    },
    clickRanger (bookingCurent) {
      const routeDate = this.$router.resolve({ path: '/reservation/contract-detail', query: { id: bookingCurent.bookingId, tab: 'rental-listing' } })
      window.open(routeDate.href, '_blank')
    },
    // end list lending room

    // create Block lending
    clickBlockCreate (infoExhibit, rentalList) {
      this.lendingDate = infoExhibit
      this.lendingBlockCurrent = rentalList
      this.isShowConfirmCreateBlock = true
    },
    // remove block lending
    clickBlockEdit (customerList) {
      if (customerList.block) {
        this.lendingBlockRemove = customerList
        this.isShowConfirmRemoveBlock = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .calendar-custom_block.ranger {
    width: 100px;
    max-width: 100px;
    position: relative;
    text-align: center;
    font-size: 10px;
    cursor: pointer;
    .rangeritem {
      min-width: unset !important;
      width: 100px;
      font-size: 10px;
    }
  }
  .calendar-custom_inner.custom-heigth {
    height: 20px;
  }
  .text-block {
    color: #707070;
    font-size: 11px;
  }
  .calendar-custom_block:first-child {
    z-index: 1;
  }
  .color-red-text {
    color: #FF0000;
  }
  .color-black-text {
    color: #000000 !important;
  }
  ::v-deep .v-select__selection{
    font-size: 12px !important;
    color: #000000 !important;
  }
  .fz-10 {
  font-size: 10px;
  }
  .fz-9 {
    font-size: 9px;
  }
  .fz-8 {
    font-size: 8px;
  }
  .fz-11 {
    font-size: 11px;
  }
  .fz-12 {
    font-size: 12px;
  }
  .fz-14 {
    font-size: 14px;
  }

  .reservation-table-container {
    position: relative;
    & .reservation-item {
      position: absolute;
      &.normal {
        margin-top: 1px;
        height: 17px;
      }
      &.block {
        height: 19px;
        border-radius: 0px;
        border: 1px solid white;
      }
    }
  }

  .calendar-row {
    &.overbook .calendar-custom_item {
      background-color: red;
    }
  }
</style>
