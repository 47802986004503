export const REMOVE_BLOCK_LENDING = `
  mutation ($data: UpdateReservationRentalItemInput!) {
    updateRentalItemReservation(data: $data) {
      id
    }
  }
`

export const UPDATE_BLOCK_LENDING = `
  mutation ($data: UpdateReservationRentalItemInput!) {
    updateRentalItemReservation(data: $data) {
      __typename
    }
  }
`
